import React from 'react';
import movistarLogo from '../assets/offers_logos/movistar_logo.jpg';
import northgateLogo from '../assets/offers_logos/northgateLogo.jpg';
import iberdolaLogo from '../assets/offers_logos/iberdolaLogo.jpg';
import naturgyLogo from '../assets/offers_logos/naturgyLogo.jpg';
import flexicarLogo from '../assets/offers_logos/flexicarLogo.jpg';
import sanitasLogo from '../assets/offers_logos/sanitasLogo.jpg';
import visionLogo from '../assets/offers_logos/visionLogo.jpg';
import lineaDirectaLogo from '../assets/offers_logos/lineaDirectaLogo.png';

interface Offer {
  logo: string;
  utp: string;
  link: string;
  isTop?: boolean; 
}

const Feed: React.FC = () => {
  const offers: Offer[] = [
    {
      logo: lineaDirectaLogo,
      utp: '<b>50€</b> DE REGALO',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=21076&gel=222441&pub=28259&org=4518&url=https%3A%2F%2Flineadirecta.uinterbox.com%2Ftracking%2Fclk%3Fact%3D21392%26gel%3D188330%26pub%3D28675%26org%3D4551',
      isTop: true,
    },
    {
      logo: movistarLogo,
      utp: 'Garantía Antiocupación y Instalación <b>GRATIS</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=17792&gel=223875&pub=28259&org=3610',
    },
    {
      logo: iberdolaLogo,
      utp: 'Hasta <b>100€ GRATIS</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=21423&gel=227420&pub=28259&org=1992',
      isTop: true,
    },
    {
      logo: naturgyLogo,
      utp: '<b>DISCUENTO 50%</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=24778&gel=211670&pub=28259&org=4673',
    },
    {
      logo: northgateLogo,
      utp: 'Coche <b>SIN ENTRADA</b> desde <b>280€/mes</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=24771&gel=217960&pub=28259&org=5345',
    },
    {
      logo: flexicarLogo,
      utp: 'Compramos tu coche <b>HOY</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=10374&gel=201928&pub=28259&org=2145',
      isTop: true,
    },
    {
      logo: sanitasLogo,
      utp: 'Seguro de salud desde <b>19,60€/mes</b>',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=22905&gel=226102&pub=28259&org=4673',
    },
    {
      logo: visionLogo,
      utp: '<b>50€ dto.</b> en gafas',
      link: 'https://feebbo.uinterbox.com/tracking/clk?act=18438&gel=211012&pub=28259&org=3674',
    },
  ];

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
        padding: '16px 16px 96px 16px',
      }}
    >
      {offers.map((offer, index) => (
        <FeedItem key={index} offer={offer} />
      ))}
    </div>
  );
};

interface FeedItemProps {
  offer: Offer;
}

const FeedItem: React.FC<FeedItemProps> = ({ offer }) => {
  const { logo, utp, link, isTop } = offer;

  return (
    <a 
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      style={{ 
        textDecoration: 'none', 
        color: 'inherit', 
        position: 'relative', 
        marginTop: isTop ? '12px' : '0', 
        display: 'block', 
      }}
    >
      {isTop && (
        <div
          style={{
            position: 'absolute',
            top: '0%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: '#17c964',
            color: '#fff',
            padding: '4px 8px',
            borderRadius: '12px',
            fontWeight: 'bold',
            fontSize: '12px',
            zIndex: 1,
          }}
        >
          más popular
        </div>
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: isTop ? '20px 16px 16px 16px' : '16px',
          borderRadius: '12px',
          boxShadow: isTop
            ? '0px 0px 0px 3px #45d483' 
            : '0 0px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)', 
          backgroundColor: '#fff',
          width: '100%',
          maxWidth: '608px',
          margin: '0 auto', // Центрирование для экранов более 608px
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ 
            width: '112px', 
            height: 'auto', 
            marginRight: '16px',
            objectFit: 'contain', 
          }}
        />
        <p 
          style={{ 
            margin: 0, 
            fontSize: '12px', 
            fontWeight: '550', 
            textAlign: 'center', 
            padding: '0px 16px 0px 0px', 
            color: '#3F3F46', 
          }}
          dangerouslySetInnerHTML={{ __html: utp }}
        />
        <div 
          style={{
            display: 'inline-block',
            padding: '8px 16px',
            borderRadius: '12px',
            backgroundColor: '#D5E2FA',
            color: '#2F6DE6',
            fontWeight: '550',
            fontSize: '14px',
          }}
        >
          Elegir
        </div>
      </div>
    </a>
  );
};

export default Feed;
