import React, { useState, useEffect } from 'react';
import AnimatedBox from './components/AnimatedBox.tsx';
import WheelOfFortune from './components/WheelOfFortune.tsx';
import Header from './components/Header.tsx';
import Timer from './components/Timer.tsx';
import Feed from './components/Feed.tsx';

function App() {
  const [showPreland, setShowPreland] = useState(true);
  const [showAnimatedBox, setShowAnimatedBox] = useState(true);

  useEffect(() => {
    // Блокируем скролл, если показывается либо AnimatedBox, либо WheelOfFortune
    if (showPreland || showAnimatedBox) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      // Восстанавливаем скролл при размонтировании компонента
      document.body.style.overflow = 'auto';
    };
  }, [showPreland, showAnimatedBox]);

  useEffect(() => {
    // Устанавливаем время отображения анимации (9 секунд)
    const duration = 9 * 1000;

    // Переключение на WheelOfFortune после завершения анимации
    const timer = setTimeout(() => {
      setShowAnimatedBox(false);
    }, duration);

    return () => {
      clearTimeout(timer); // Очищаем таймер, если компонент размонтируется
    };
  }, []);

  const handleButtonClick = () => {
    setShowPreland(false); // Скрываем prelandContainer
  };

  return (
    <div className="App font-sans">
      <style>
        {`
          @media only screen and (max-width: 768px) {
            .prelandInner {
              max-width: 100%; // Устанавливаем ширину на 100% для мобильных устройств
            }
          }
        `}
      </style>

      {showPreland ? (
        <div 
          className='prelandContainer'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            backgroundColor: '#27272A',
            padding: '80px 32px 0px 32px',
            height: '100vh',
          }}
        >
          <div 
            className='prelandInner'
            style={{
              padding: '8px 16px 8px 16px',
              backgroundColor: '#ffffff',
              borderRadius: '20px',
              width: '100%',
              maxWidth: '468px',
              overflow: 'hidden',
            }}
          >
            {showAnimatedBox ? (
              <AnimatedBox />
            ) : (
              <WheelOfFortune onButtonClick={handleButtonClick} />
            )}
          </div>
        </div>
      ) : (
        <div 
          className='feedContainer'
        >
          <Timer startFrom={5} dangerFrom={3} />
          <Header />
          <Feed />
        </div>
      )}
    </div>
  );
}

export default App;
