import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import ProgressBar from './ProgressBar';

// Импорт аватарок
import ava1 from '../assets/avatars/ava1.png';
import ava2 from '../assets/avatars/ava2.png';
import ava3 from '../assets/avatars/ava3.png';
import ava4 from '../assets/avatars/ava4.png';
import ava5 from '../assets/avatars/ava5.png';
import ava6 from '../assets/avatars/ava6.png';
import ava7 from '../assets/avatars/ava7.png';
import ava8 from '../assets/avatars/ava8.png';

// Импорт иконок офферов
import { ReactComponent as Offer1 } from '../assets/offers_icons/offer_1.svg';
import { ReactComponent as Offer2 } from '../assets/offers_icons/offer_2.svg';
import { ReactComponent as Offer3 } from '../assets/offers_icons/offer_3.svg';
import { ReactComponent as Offer4 } from '../assets/offers_icons/offer_4.svg';
import { ReactComponent as Offer5 } from '../assets/offers_icons/offer_5.svg';
import { ReactComponent as Offer6 } from '../assets/offers_icons/offer_6.svg';
import { ReactComponent as Offer7 } from '../assets/offers_icons/offer_7.svg';
import { ReactComponent as Offer8 } from '../assets/offers_icons/offer_8.svg';

const CircleAnimation = () => {
  const circlesRef = useRef<(HTMLImageElement | null)[]>([]);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const textDivRef = useRef<(HTMLDivElement | null)[]>([]);
  const [secondsLeft, setSecondsLeft] = useState(9); // Стартовое значение для обратного отсчета

  // Массив с изображениями аватарок
  const avatars = [ava3, ava2, ava1, ava8, ava7, ava6, ava5, ava4];

  // Массив с текстом и изображениями для дополнительных дивов
  const textAndImages = [
    { text: 'Ahorra hasta 95€...', img: <Offer1 /> },
    { text: 'Recibe 10€ con...', img: <Offer2 /> },
    { text: 'Descuento 5% con...', img: <Offer3 /> },
    { text: 'Compra 2 por 1 sin...', img: <Offer4 /> },
    { text: '3 mes inicial grat...', img: <Offer5 /> },
    { text: 'Hasta 10% de descu...', img: <Offer6 /> },
    { text: 'Obtén hasta 15%...', img: <Offer7 /> },
    { text: 'Acceso premium...', img: <Offer8 /> },
  ];

  useEffect(() => {
    const radius = 100;
    const totalCircles = 8;
    const stepAngle = 360 / totalCircles;
    const durationPerStep = 1.1; // Время на каждый этап
    const fadeDuration = 0.5; // Длительность перехода между текстами

    // Инициализация положения аватарок
    circlesRef.current.forEach((circle, i) => {
      if (circle) {
        const angle = stepAngle * i;
        const x = Math.cos((angle * Math.PI) / 180) * radius;
        const y = Math.sin((angle * Math.PI) / 180) * radius;
        gsap.set(circle, { x, y, filter: 'grayscale(100%)', scale: 1 });
      }
    });

    // Обратный отсчет
    const countdownInterval = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev > 1) {
          return prev - 1;
        } else {
          clearInterval(countdownInterval); // Останавливаем обратный отсчет на 1
          return 1;
        }
      });
    }, 1000);

    const applyEffects = (index: number, isFirstPass = false) => {
      if (index >= totalCircles || index < 0) return;

      const currentCircle = circlesRef.current[index];
      const nextIndex = (index - 1 + totalCircles) % totalCircles; // Изменение направления с учетом отрицательных значений
      const nextCircle = circlesRef.current[nextIndex];
      const currentTextDiv = textDivRef.current[index];
      const nextTextDiv = textDivRef.current[nextIndex];

      if (currentCircle && nextCircle && currentTextDiv && nextTextDiv) {
        const tl = gsap.timeline({
          onComplete: () => applyEffects(nextIndex), // Переход к следующему аватару после завершения текущего цикла
        });

        // Анимация для вращения и скрытия текущего аватара
        tl.to(containerRef.current, {
          rotation: `+=${stepAngle}`,
          duration: durationPerStep,
          ease: 'power3.inOut',
          onUpdate: () => {
            const currentRotation = (gsap.getProperty(containerRef.current, 'rotation') as number) % 360;

            // Реверсивное вращение аватарок
            circlesRef.current.forEach((circle) => {
              if (circle) {
                gsap.set(circle, { rotation: -currentRotation });
              }
            });

            // Реверсивное вращение текста и изображения в центре
            textDivRef.current.forEach((div) => {
              if (div) {
                gsap.set(div, { rotation: -currentRotation });
              }
            });
          },
        })
        .to(currentCircle, {
          filter: isFirstPass ? 'grayscale(100%)' : 'blur(5px)', // Первый проход без размытия
          opacity: isFirstPass ? 1 : 0, // Первый проход без скрытия
          duration: durationPerStep,
          ease: 'power3.inOut',
        }, `-=${durationPerStep}`)
        .to(nextCircle, {
          filter: 'grayscale(0%)',
          scale: 1.6,
          duration: durationPerStep,
          ease: 'power4.inOut',
        }, `-=${durationPerStep}`)
        .to(currentTextDiv, {
          opacity: 0, // Плавное исчезновение текущего текста и изображения
          duration: fadeDuration,
          ease: 'power3.inOut',
        }, `-=${durationPerStep}`)
        .to(nextTextDiv, {
          opacity: 1, // Плавное появление следующего текста и изображения
          duration: fadeDuration,
          ease: 'power3.inOut',
          delay: 0, // Ждем пока текущий текст исчезнет
        }, `-=${durationPerStep - fadeDuration}`);
      }
    };

    applyEffects(6, true); // Начинаем с 6-го аватара, первый проход

    return () => {
      clearInterval(countdownInterval); // Очищаем интервал обратного отсчета
    };
  }, []);

  return (
    <div>
      <div
        className='shadow-inner'
        id="relativeRound"
        ref={containerRef}
        style={{
          position: 'relative',
          width: '120px',
          height: '120px',
          margin: '100px auto',
          borderRadius: '50%',
          backgroundColor: '#E4E4E7',
        }}
      >
        {avatars.map((avatar, i) => (
          <img
            key={i}
            ref={(el) => (circlesRef.current[i] = el)}
            src={avatar}
            alt={`Avatar ${i + 1}`}
            style={{
              width: '40px',
              height: '40px',
              borderRadius: '50%',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          />
        ))}

        {textAndImages.map((item, i) => (
          <div
            key={i}
            ref={(el) => (textDivRef.current[i] = el)}
            style={{
              position: 'absolute',
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              padding: '0px 12px 0px 12px',
              alignItems: 'center',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              opacity: i === 6 ? 1 : 0, // Начальное значение для активного текста
            }}
          >
            <div style={{ borderRadius: "50%", marginBottom: "8px" }}>{item.img}</div>
            <p style={{ fontSize: "12px", lineHeight: "20px", fontWeight: "600", color: "#3F3F46", }}>{item.text}</p>
          </div>
        ))}
      </div>
      <p style={{ textAlign: 'center', fontSize: '16px', color: '#27272A', fontWeight: "550", marginTop: '-16px' }}>
        Es tu turno de elegir un regalo en<br />{secondsLeft} segundos
      </p>
      <div style={{ margin: '20px 0px 16px 0px' }}>
      <ProgressBar duration={9} />
      </div>
    </div>
  );
};

export default CircleAnimation;
