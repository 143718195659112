import React, { useRef, useEffect, useState } from 'react';
import gsap from 'gsap';
import { ReactComponent as QuestionIcon } from '../assets/offers_icons/question_icon.svg';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import successAnimation from '../assets/success.lottie';
import { Button } from '@nextui-org/react';

interface WheelOfFortuneProps {
  onButtonClick: () => void;
}

const WheelOfFortune: React.FC<WheelOfFortuneProps> = ({ onButtonClick }) => {
  const wheelRef = useRef(null);
  const pulseRef = useRef(null);
  const [showFinalText, setShowFinalText] = useState(false);
  const [showLottie, setShowLottie] = useState(false);

  useEffect(() => {
    const spins = Math.floor(Math.random() * 4) + 4; // Количество оборотов от 4 до 7
    const degree = 360 * spins;
    const duration = 10; // Длительность анимации

    gsap.to(wheelRef.current, {
      rotation: degree,
      duration: duration,
      ease: 'power4.out',
      delay: 0.6,
      onComplete: () => setShowFinalText(true), // Показать текст после завершения анимации
    });

    // Показать анимацию .lottie за 1 секунду до завершения вращения
    const lottieTimer = setTimeout(() => {
      setShowLottie(true);
    }, (duration - 1) * 1000); // 1 секунда до конца анимации

    // Анимация пульсации
    gsap.fromTo(
      pulseRef.current,
      { scale: 1 },
      {
        scale: 1.2,
        repeat: -1,
        yoyo: true,
        duration: 1,
        ease: 'power1.inOut',
      }
    );

    return () => clearTimeout(lottieTimer); // Очистка таймера при размонтировании компонента
  }, []);

  return (
    <div>
      <div style={{ position: 'relative', margin: 'auto', maxWidth: '80vw' }}>
        <svg
          ref={wheelRef}
          viewBox="0 0 784 784"
          xmlns="http://www.w3.org/2000/svg"
          style={{ transformOrigin: 'center', display: 'block', margin: 'auto', width: '100%', height: 'auto' }}
        >
          <circle cx="392" cy="392" r="300" fill="#f5f5f5" stroke="#333" strokeWidth="2" />
          <circle cx="392" cy="392" r="292" fill="none" stroke="#333" strokeWidth="2" />
          <path d="M488 660L392 392 270 649C349 687 429 681 488 660Z" fill="#E4E4E7" />
          <path d="M295 124L392 392 513 134C434 97 354 102 295 124Z" fill="#E4E4E7" />
          <path d="M124 488L392 392 134 271C97 349 103 429 124 488Z" fill="#E4E4E7" />
          <path d="M660 295L392 392 650 513C687 434 682 354 660 295Z" fill="#E4E4E7" />
          <text x="540" y="405" transform="rotate(1, 392, 392)" fill="#000" fontSize="32" fontWeight="600">8</text>
          <text x="540" y="405" transform="rotate(47, 392, 392)" fill="#000" fontSize="32" fontWeight="600">4</text>
          <text x="540" y="405" transform="rotate(92, 392, 392)" fill="#000" fontSize="32" fontWeight="600">5</text>
          <text x="540" y="405" transform="rotate(137, 392, 392)" fill="#000" fontSize="32" fontWeight="600">0</text>
          <text x="540" y="405" transform="rotate(182, 392, 392)" fill="#000" fontSize="32" fontWeight="600">7</text>
          <text x="540" y="405" transform="rotate(227, 392, 392)" fill="#000" fontSize="32" fontWeight="600">5</text>
          <text x="540" y="405" transform="rotate(270, 392, 392)" fill="#000" fontSize="32" fontWeight="600">1</text>
          <text x="540" y="405" transform="rotate(315, 392, 392)" fill="#000" fontSize="32" fontWeight="600">10</text>
        </svg>

        <div
          ref={pulseRef}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '15%',
            height: '15%',
            backgroundColor: '#fff',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            transform: 'translate(-50%, -50%)',
            boxShadow: '0 0 15px rgba(0, 0, 0, 0.2)',
            zIndex: 2, // Слой выше текста
          }}
        >
          <QuestionIcon style={{ width: '50%', height: '50%' }} />
        </div>

        {showLottie && (
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '120%', // Размер анимации относительно контейнера
              height: '120%',
              zIndex: 3, // Слой выше всех остальных
            }}
          >
            <DotLottieReact
              src={successAnimation}
              loop={false}
              autoplay={true}
            />
          </div>
        )}

        <svg
          viewBox="0 0 38 33"
          xmlns="http://www.w3.org/2000/svg"
          style={{
            position: 'absolute',
            height: '8%', // Высота стрелки как процент от родительского элемента
            right: '10%', // Смещение вправо как процент от родительского элемента
            top: '50%',
            transform: 'translateY(-50%)',
            zIndex: 2, // Слой выше текста
          }}
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M33.75 32.12c1.14-.3 2.7-2.16 2.7-5.75 0-10.68-3.49-19.14-14.28-19.14h-1.76V4.3c0-1.23-1.4-1.93-2.38-1.19L.16 16.42s-.02.48 0 .95c.02.42.22.84.6 1.12l17.27 13c.98.74 2.38.04 2.38-1.19v-2.92h8.82v5.52s2.39-.14 4.22-.7l.2-.06z"
              fill="#281501"
              opacity=".23"
            />
            <path
              d="M37.4 25.83c0 2.82-.83 4.25-2.2 4.76-1.86.61-4.42.76-4.42.76v-7.14l6.63 1.62z"
              fill="#000"
              opacity=".27"
            />
            <path
              d="M1.71 14.88L19.58 1.56a1.49 1.49 0 012.38 1.19v2.92h1.77C34.52 5.68 38 14.14 38 24.82c0 3.7-1.66 5.56-2.8 5.77 1.64-.73 1.73-4.76-1.94-4.76H21.92c8.56-2.17 8.08-11.32 6.12-19.62"
              fill="#0A0A0A"
              opacity=".27"
            />
            <path
              className="sc-WheelOfFortunePopup-iconArrow-arrow"
              d="M2.3 13.69l17.28-13a1.49 1.49 0 012.38 1.19V4.8h1.77C34.52 4.8 38 13.26 38 23.94c0 3.7-.81 6.02-2.8 6.65 2.32-1.15 1.73-5.64-1.94-5.64h-11.3v2.92c0 1.23-1.4 1.93-2.38 1.2l-17.27-13a1.49 1.49 0 010-2.39"
              fill="#E94059"
            />
            <path
              d="M28.04 5.33C36.63 7.55 38 16.74 38 23.93c0 3.71-.81 6.04-2.8 6.66 2.32-1.15 1.73-5.64-1.94-5.64H21.92c8.56-2.17 8.08-11.32 6.12-19.62"
              fill="#1D1D1B"
              opacity=".16"
            />
          </g>
        </svg>
      </div>

      <div style={{ textAlign: 'center', marginTop: '-16px', marginBottom: '12px' }}>
        {!showFinalText ? (
          <div style={{ fontSize: '16px', color: '#27272A', fontWeight: '550' }}>
            Determinamos la cantidad de regalos disponibles para ti
          </div>
        ) : (
          <div style={{ fontSize: '16px', color: '#27272A', fontWeight: '550' }}>
            <p style={{ marginBottom: '16px' }}>¡Felicidades!<br />Dispones de 8 regalos.<br/>Elige uno. Tienes 5 minutos.</p>
            <Button fullWidth color="primary" size="lg" variant="shadow" onPress={onButtonClick}>
              Obtén tu regalo
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default WheelOfFortune;
