import React from 'react';
import { ReactComponent as Logo } from '../assets/logo.svg';

const Header: React.FC = () => {
    return (
      <header
      style={{ gap: '12px', backgroundColor: '#ffffff', }}
      >
        <div style={{ padding: '16px', width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', borderBottom: '1px solid #e4e4e7', }}><div><Logo /></div></div>
        <p style={{ marginTop: '16px', marginBottom: '8px', fontSize: '20px', fontWeight: '600', textAlign: 'center', }}>Elige tu regalo.<br/>Tienes 5 minutos.</p>
      </header>
    );
  };
  
  export default Header;