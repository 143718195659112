import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';

interface ProgressBarProps {
  duration: number; 
}

const ProgressBar: React.FC<ProgressBarProps> = ({ duration }) => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const progressFillRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (progressFillRef.current) {
      gsap.to(progressFillRef.current, {
        width: '100%',
        duration: duration,
        ease: 'linear',
      });
    }
  }, [duration]);

  return (
    <div
      ref={progressBarRef}
      style={{
        width: '100%',
        height: '20px',
        backgroundColor: 'hsl(240deg 4.88% 83.92% / 50%)',
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <div
        ref={progressFillRef}
        style={{
          width: '0%',
          height: '100%',
          backgroundColor: 'hsl(212deg 100% 46.67% / 1)',
          borderRadius: '10px',
        }}
      />
    </div>
  );
};

export default ProgressBar;
